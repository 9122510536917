@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.field {
  @include transition;
  position: relative;
  margin-bottom: 15px;

  h2 {
    color: $primary-color;
  }
  .lead {
    margin-top: 10px;
    span {
      color: $primary-color;
    }
  }
}
