@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .indicator {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px;
    h2 {
      color: $primary-color;
      margin: 0px 20px 0px 0px;
    }
    img {
      margin-right: 8px;
    }
  }
  .toggle {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: transparent;
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition;
    padding: 5px;
    font-size: 30px;
    &.open {
      transform: rotate(180deg);
    }
  }
  .panel {
    overflow: hidden;
    margin-bottom: 20px;
    @include transition;
    .group {
      margin-bottom: 20px;
      label {
        font-family: "Montserrat-Regular", sans-serif;
        font-size: 18px;
        line-height: 17px;
        color: $primary-color;
        white-space: nowrap;
        display: block;
        margin-bottom: 10px;
      }
      .options {
        display: flex;
        flex-direction: column;
        button {
          background-color: transparent;
          font-family: "Montserrat-Regular", sans-serif;
          font-size: 14px;
          line-height: 17px;
          color: #818283;
          text-align: left;
          display: flex;
          align-items: center;
          padding: 5px 0px;
          &.checked {
            color: $white;
            .square {
              svg {
                display: block;
              }
            }
          }
          .square {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            min-width: 24px;
            height: 24px;
            border-radius: 6px;
            border: solid 1px $inputBg;
            background-color: $inputBg;
            cursor: pointer;
            >svg {
              display: none;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}