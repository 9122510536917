@import '../../../../styles/variables';
@import "../../../../styles/_mixins.scss";

.resetpwd-form{
  width: 100%;
  .form {
    // margin-bottom: $margin-lg;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: 12px;
    }
    .message-succeed{
      @include regular;
      color : $secondary-color;
      font-size: $regular;
    }

    .submit {
      margin-top: $margin-lg;
    }

    button.btnSucceed{
      margin-top: $margin-lg;
      max-width: 100%;
      white-space: nowrap;
      margin-bottom: $margin-sm;
    }

  }

}
