@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  display: flex;
  padding: $margin-md;
  @media screen and (min-width: $lg-screen) {
    padding: $margin-lg;
  }
  h1 {
    color: $primary-color;
  }
}
